import React from "react";

const TattooCard = ({ src, onClick }) => (
  <div style={{ margin: "1em", maxWidth: "300px" }}>
    <img
      src={src}
      alt="Tatoo Flash"
      style={{ width: "100%", borderRadius: "8px", cursor: "pointer" }}
      onClick={onClick}
    />
  </div>
);

export default TattooCard;
