import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => (
  <div
    style={{
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      height: "50px",
      backgroundColor: "#FFF",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    }}
  >
    <Link to="/">
      <img src="flashs/illu2.png" alt="Flashs" style={{ height: "30px" }} />
    </Link>
    <Link to="/infos">
      <img src="buttons/home.png" alt="Infos" style={{ height: "30px" }} />
    </Link>
  </div>
);

export default NavBar;
