import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
const InfoPage = () => {
  const position = [48.880915, 2.337349]; // Coordonnées pour 22 Rue de Douai 75009 Paris

  return (
    <div style={{ background: "#FFD1DC", height: "100vh" }}>
      <div
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            borderRadius: "50%",
            overflow: "hidden",
            marginRight: "10px",
          }}
        >
          <img
            src="delabicherie.jpg"
            alt="Your Name"
            style={{ width: "100px" }}
          />
        </div>
        <h1>Delabicherie</h1>
      </div>

      <MapContainer
        center={position}
        zoom={15}
        style={{ width: "100%", height: "50vh" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}>
          <Popup>22 Rue de Douai 75009 Paris</Popup>
        </Marker>
      </MapContainer>

      <div style={{ padding: "20px" }}>
        <p>Mon univers</p>
      </div>
    </div>
  );
};

export default InfoPage;
