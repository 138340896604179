import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import InfoPage from "./components/InfoPage";
import NavBar from "./components/NavBar";

const App = () => {
  return (
    <Router>
      <div style={{ paddingBottom: "60px" }}>
        {" "}
        <Routes>
          <Route path="/" element={<LandingPage />} />{" "}
          <Route path="/infos" element={<InfoPage />} />
        </Routes>
        <NavBar />
      </div>
    </Router>
  );
};

export default App;
