import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const TattooModal = ({ isOpen, imageSrc, onClose }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "1em",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "none",
            border: "none",
            fontSize: "1.5em",
          }}
        >
          ×
        </button>
        <img
          src={imageSrc}
          alt="Tatoo Flash Large"
          style={{ maxWidth: "80vw", maxHeight: "80vh" }}
        />
        <div style={{ marginTop: "1em" }}>
          <a
            href="https://calendly.com/delabicherie-tattoo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="buttons/booking.png" width="150" alt="booking" />
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default TattooModal;
