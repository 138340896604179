import React, { useState } from "react";
import TattooCard from "./TattooCard";
import TattooModal from "../Modal/TattooModal";

const tattoos = [
  { id: 1, src: "flashs/illu1.png", size: 15, priceMini: 200, priceMaxi: 500 },
  { id: 1, src: "flashs/illu2.png" },
  { id: 1, src: "flashs/illu3.png" },
  { id: 1, src: "flashs/illu4.png" },
  { id: 1, src: "flashs/illu5.png" },
  { id: 1, src: "flashs/illu6.png" },
  { id: 1, src: "flashs/illu7.png" },
  { id: 1, src: "flashs/illu8.png" },
  { id: 1, src: "flashs/illu9.png" },
  { id: 1, src: "flashs/illu10.png" },
  // ...
];

const LandingPage = () => {
  const [selectedTattoo, setSelectedTattoo] = useState(null);

  return (
    <div
      style={{
        backgroundColor: "#ffd1dc",
        minHeight: "100vh",
        padding: "3em 1em",
        boxSizing: "border-box",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "2em" }}>
        <h1 style={{ color: "#fff", fontSize: "2.5em", letterSpacing: "2px" }}>
          delabicherie
        </h1>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {tattoos.map((tattoo) => (
          <TattooCard
            key={tattoo.id}
            src={tattoo.src}
            onClick={() => setSelectedTattoo(tattoo.src)}
          />
        ))}
      </div>

      <TattooModal
        isOpen={selectedTattoo !== null}
        imageSrc={selectedTattoo}
        onClose={() => setSelectedTattoo(null)}
      />
    </div>
  );
};

export default LandingPage;
